<template>
  <div>
    <div>
      <p class="title">账户中心</p>
      <p class="solid">|数据查询</p>
      <p style="textAlign:left;padding:10px 0 0 25px;fontSize:16px">商家接口余额</p>
      <div style="textAlign:left;padding:10px 0 0 19px"><el-button type="primary" @click="query">一键查询</el-button></div>
    </div>
    <p class="solids">|账户明细</p>
    <div style="textAlign:left">
      <p class="searchtime"><span style="font-size:14px;margin-right:19px;">记账时间</span>
        <el-date-picker v-model="agent_time" @change="getlist(2, 1)" type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss" :picker-options="a_pickerOptions" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" align="right">
        </el-date-picker>
      </p>
      <br>
      <p style="display: inline-block;width: 500px;">
        <label class="type_lab" for="search">业务单号</label><el-input v-model="order_no" name="search" placeholder="请输入业务单号"
          class="goods_search"></el-input>
      </p>
      <Button type="primary" @click="getlist(2, 1)">查询</Button>
      <Button type="primary" style="margin:0 10px 0 10px" @click="reset">重置</Button>
      <Button type="primary" @click="getlist(1)">导出</Button>
      <span class="expland" v-if="upfold" @click="upfold = false">收起<i class="el-icon-arrow-up"></i></span><span
        class="expland" v-else @click="upfold = true">展开<i class="el-icon-arrow-down"></i></span>
      <div style="margin-top: 15px;" v-show="upfold">
        <label for="yewu_type" class="type_lab">商品归属</label>
        <el-select v-model="goods_belong" @change="getlist(2, 1)" filterable placeholder="请选择商品归属" name='yewu_type'>
          <el-option v-for="item in supplier_options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <label class="type_lab" for="select">充值状态</label>
        <el-select v-model="recharge_state" @change="getlist(2, 1)" clearable placeholder="请选择充值状态" name="select">
          <el-option v-for="item in txoptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option></el-select>
        <label class="type_lab" for="search">商品ID</label><el-input v-model="goodsId" name="search" placeholder="请输入商品ID"
          style="width: 370px;" class="goods_search"></el-input>
      </div>
      <p style="padding:10px 0 10px 20px;fontSize:15px" v-if="book_type == ''">
        收入：<span style="paddingRight:15px;color:blue">{{ count.income_num }}笔,共{{ count.income_price }}元</span>
        支出：<span style="paddingRight:15px;color:red">{{ count.expenditure_num }}笔,共{{ count.expenditure_price }}元</span>
        第三方接口平台支出：<span style="color:red">{{ count.good_purchase_price_all }}元</span></p>

      <p span v-if="book_type == 1" style="padding:10px 0 10px 20px;fontSize:15px">收入：<span
          style="paddingRight:15px;color:blue">{{ count.income_num }}笔,共{{ count.income_price }}元</span></p>
      <p v-if="book_type == 2" style="padding:10px 0 10px 20px;fontSize:15px"> 支出：<span
          style="paddingRight:15px;color:red">{{ count.expenditure_num }}笔,共{{ count.expenditure_price }}元</span>
        <!-- 第三方接口平台支出：<span style="color:red">{{count.good_purchase_price_all}}元</span> -->
      </p>
      <div style="margin:0 0 10px 15px">
        <el-radio-group v-model="book_type" name='yewu_type' size="small" @change="getlist(2, 1)">
          <el-radio-button label="">全部</el-radio-button>
          <el-radio-button label="1">收入</el-radio-button>
          <el-radio-button label="2">支出</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-table max-height="340" :data="tableData" border style="width: 99%;marginLeft:10px">
          <el-table-column prop="create_time" label="时间">
          </el-table-column>
          <el-table-column prop="remark" label="商品说明" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="order_no" label="业务单号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="type_str" label="业务类型">
          </el-table-column>
          <el-table-column prop="money" label="收支金额">
            <template slot-scope="scope">
              <span :style="{ 'color': scope.row.sz == 1 ? 'blue' : 'red' }">{{ scope.row.money }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="look(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top:5px;textAlign:center" background prev-text='上一页' next-text='下一页'
          layout="total , prev, pager, next, jumper" @current-change="current_change" :total="total" :page-size="pagesize"
          :current-page.sync="currentPage">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="商家余额" center :visible.sync="dialogVisible" width="500px">
      <el-table :data="blance" style="width: 100%;height:500px;overflow: auto;">
        <el-table-column prop="name" label="商家名称">
        </el-table-column>
        <el-table-column prop="balance" label="商家余额">
        </el-table-column>

      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="详情" center :visible.sync="detail" width="50%">
      <el-row>
        <el-col :span="8">商品说明</el-col>
        <el-col :span="16">{{ value.remark }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">记账类型</el-col>
        <el-col :span="16">{{ value.accounting_type }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">业务单号</el-col>
        <el-col :span="16">{{ value.order_no }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">时间</el-col>
        <el-col :span="16">{{ value.create_time }}</el-col>
      </el-row>
      <el-row v-if="value.state == '已退款'">
        <el-col :span="8">退款时间</el-col>
        <el-col :span="16">{{ value.refund_time }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">业务类型</el-col>
        <el-col :span="16">{{ value.type }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">关联商户号</el-col>
        <el-col :span="16">{{ value.m_account }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">充值账号</el-col>
        <el-col :span="16">{{ value.recharge_account }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">充值状态</el-col>
        <el-col :span="16">{{ value.state }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">充值数量</el-col>
        <el-col :span="16">{{ value.num }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">收支金额</el-col>
        <el-col :span="16">
          <span :style="{ 'color': value.sz == 1 ? 'blue' : 'red' }">{{ value.money }}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="8">商品归属</el-col>
        <el-col :span="16">{{ value.supplier_str }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">账户交易变更前金额（元）</el-col>
        <el-col :span="16">{{ value.before_money }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="8">账户交易变更后金额（元）</el-col>
        <el-col :span="16">{{ value.after_money }}</el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  inject: ['chanlist'],
  data() {
    return {
      value: "",
      detail: false,
      blance: [],
      dialogVisible: false,
      count: "",
      currentPage: 1,
      pagesize: 10,
      total: 0,
      tableData: [],
      book_type: '',
      goodsId: '',
      txoptions: [{
        value: '2',
        label: '充值成功'
      }, {
        value: '3',
        label: '充值失败'
      }, {
        value: '1',
        label: '充值中'
      }, {
        value: '4',
        label: '已退款'
      }],
      recharge_state: '',
      supplier_options: [{
        value: '1',
        label: '易派网'
      }, {
        value: '2',
        label: '卡商网'
      }, {
        value: '3',
        label: '交壹吧'
      }, {
        value: '4',
        label: '福禄网'
      }, {
        value: '5',
        label: '硕软网'
      }, {
        value: '6',
        label: '凤凰数卡'
      }, {
        value: '7',
        label: '承天下'
      }, {
        value: '8',
        label: '橙券'
      }, {
        value: '9',
        label: '彦浩'
      }, {
        value: '10',
        label: '蓝色兄弟'
      }, {
        value: '11',
        label: '达尚'
      }, {
        value: '12',
        label: '云金'
      }, {
        value: '13',
        label: '子轩'
      }, {
        value: '14',
        label: '娱尚'
      }, {
        value: '15',
        label: '凯捷数娱'
      }, {
        value: '16',
        label: '商盟'
      }, {
        value: '17',
        label: '乐娱'
      }, {
        value: '18',
        label: '裕寅旺'
      }, {
        value: '19',
        label: '百妙'
      }, {
        value: '20',
        label: '旧梦'
      }, {
        value: '21',
        label: '雷鸟'
      }, {
        value: '166',
        label: '人工代充'
      }, {
        value: '30',
        label: '樊登读书'
      }, {
        value: '167',
        label: '循环下单'
      }, {
        value: '22',
        label: '迅银'
      }, {
        value: '23',
        label: '乐充'
      }, {
        value: '31',
        label: '农行兑换'
      }, {
        value: '32',
        label: '京东兑换'
      }, {
        value: '24',
        label: '分销E'
      },
      {
        value: '34',
        label: '本香'
      }, {
        value: '35',
        label: '86885'
      }, {
        value: '36',
        label: '喜马拉雅'
      }, {
        value: '25',
        label: '恒易迅'
      }, {
        value: '29',
        label: '恒易迅代票'
      }, {
        value: '26',
        label: '西柚'
      }, {
        value: '27',
        label: '拉布拉卡'
      }, {
        value: '28',
        label: '胜始科技'
      }, {
        value: '37',
        label: '樊登会员'
      }],
      goods_belong: "",
      upfold: false,
      order_no: "",
      agent_time: [],
      a_pickerOptions: {
        shortcuts: [
          {
            text: '二十四小时内',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
    }
  },
  mounted() {
    this.chanlist('3-1', '3')
    this.settime()
    this.getlist(2)
  },
  methods: {
    look(row) {
      this.$axios.post('/supplier/billing/center.detail', { id: row.id }).then((res) => {
        if (res.data.code == 20000) {
          this.value = res.data.data
          this.detail = true
        } else {
          this.$message({
            type: "warning",
            message: res.data.message
          })
        }
      })
    },
    query() {
      this.$axios.post("/supplier/merchant.interface.balance")
        .then((res) => {
          if (res.data.code == 20000) {
            this.blance = res.data.data
            this.dialogVisible = true
          } else {
            this.blance = []
            this.dialogVisible = true
          }

        })
    },
    reset() {
      this.settime()
      this.order_no = ''
      this.goods_belong = ''
      this.recharge_state = ''
      this.goodsId = ''
      this.book_type = ''
      this.currentPage = 1
      this.getlist(2)
    },
    formatDate(now) {
      let year = now.getFullYear();  //取得4位数的年份
      let month = now.getMonth() + 1;  //取得日期中的月份，其中0表示1月，11表示12月
      let date = now.getDate();      //返回日期月份中的天数（1到31）
      let hour = now.getHours();     //返回日期中的小时数（0到23）
      let minute = now.getMinutes(); //返回日期中的分钟数（0到59）
      let seconds = now.getSeconds(); //返回日期中的秒数（0到59）
      month < 10 ? month = '0' + month : month;
      date < 10 ? date = '0' + date : date;
      hour < 10 ? hour = '0' + hour : hour;
      minute < 10 ? minute = '0' + minute : minute;
      seconds < 10 ? seconds = '0' + seconds : seconds;
      return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + seconds;
    },
    current_change(page) {
      this.currentPage = page;
      this.getlist(2)
    },
    settime() { this.agent_time = [this.formatDate(new Date(new Date(new Date()).getTime() - 3600 * 1000 * 12)), this.formatDate(new Date())] },
    getlist(exp, page) {
      if (page == 1) {
        this.currentPage = 1
      }
      var time;
      if (this.agent_time == null) {
        time = ''
      } else {
        time = this.agent_time[0] + ',' + this.agent_time[1]
      }
      this.$axios.post('/supplier/billing/center', { export: exp, order_no: this.order_no, time: time, supplier_id: this.goods_belong, state: this.recharge_state, gid: this.goodsId, page: this.currentPage, limit: this.pagesize, type: this.book_type }).then((res) => {
        if (res.data.code == 20000) {
          if (exp == 1) {
            window.open(res.data.data, '_blank')
          } else {
            this.tableData = res.data.data.list.rows
            this.total = res.data.data.list.total
            this.count = res.data.data.count
          }
        } else {
          this.tableData = []
          this.total = 0
          this.count = ''
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.title {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-bottom: 1px solid gray;
  text-align: left;
  font-size: 20px;
}

.solid {
  height: 40px;
  line-height: 40px;
  padding-left: 25px;
  border-bottom: 1px dashed gray;
  text-align: left;
  font-size: 16px;
  color: blue;
}

.solids {
  height: 60px;
  line-height: 80px;
  padding-left: 25px;
  border-bottom: 1px dashed gray;
  text-align: left;
  font-size: 16px;
  color: blue;
}

.searchtime {
  width: 500px;
  margin: 10px 18px 10px 18px;
  display: inline-block
}

.type_lab {
  height: 34px;
  width: 90px;
  display: inline-block;
  line-height: 34px;
  text-align: center;
  font-size: 14px;
}

.goods_search {
  width: 400px;
  height: 30px;
  text-align: left
}

.expland {
  font-size: 13px;
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  color: #3743D6;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
}

.el-row {
  border-bottom: 1px solid black;
  height: 30px;
  padding-top: 10px;
  font-size: 15px;
}
</style>